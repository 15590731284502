import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import React, { forwardRef, useEffect, useMemo, useRef, useState, } from 'react';
import { useStateFromProp } from '@knapsack/hooks';
import { isEmpty } from '@knapsack/utils';
import { mergeRefs } from 'react-merge-refs';
import { Backdrop } from '../backdrop/index.js';
import { IconButtonTertiary } from '../button/index.js';
import { TextBody, TextHeading } from '../text/index.js';
import { TabBar } from '../tabs/index.js';
import { modal, modalContent, modalHeader, modalBody, modalFooter, modalTabbedContent, modalCloseButton, } from './modal.css.js';
import { useFocusTrap } from '../../utils/focus-trap.js';
export const Modal = forwardRef(({ body, footer, onToggle, opened = false, size = 'default', subtitle, tabs, tabBarStyles, testId, title, preventFocusTrap = false, bodyClassName, instantOpen = false, preventEscKeyClose = false, hideCloseButton = false, preventClose = false, }, ref) => {
    const internalRef = useRef(null);
    const _ref = useMemo(() => mergeRefs([internalRef, ref]), [internalRef, ref]);
    /**
     * STATE MANAGEMENT
     * - Initial state is always closed to prevent flashing
     * - The `opened` props triggers the opening/closing animations
     * - Delay matches the css animation in modal.css.ts
     */
    const [isModalOpen] = useStateFromProp(opened);
    const [modalState, setModalState] = useState('closed');
    useEffect(() => {
        if (isModalOpen) {
            setModalState('opening');
        }
        else {
            setModalState('closing');
        }
    }, [isModalOpen]);
    useEffect(() => {
        const delay = instantOpen ? 0 : 250;
        const timeoutId = setTimeout(() => {
            if (modalState === 'opening') {
                setModalState('opened');
            }
            if (modalState === 'closing') {
                setModalState('closed');
            }
        }, delay);
        return () => clearTimeout(timeoutId);
    }, [modalState, instantOpen]);
    useFocusTrap({
        trapElement: internalRef.current,
        active: !preventFocusTrap && modalState === 'opened',
    });
    if (modalState === 'closed')
        return null;
    return (_jsxs("div", { "aria-modal": true, className: modal({
            state: modalState,
            size,
            instantOpen,
        }), "data-testid": testId, ref: _ref, role: "dialog", tabIndex: -1, children: [_jsxs("div", { className: modalContent({
                    opened: modalState === 'opened',
                    instantOpen,
                }), children: [(title || subtitle) && !hideCloseButton && (_jsxs("header", { className: modalHeader, children: [title && React.isValidElement(title) && title, title && typeof title === 'string' && (_jsx(TextHeading, { tag: "h2", size: "small", spacing: "small", children: title })), subtitle && typeof subtitle === 'string' && (_jsx(TextBody, { size: "medium", color: "subtle", spacing: "small", children: subtitle })), subtitle && typeof subtitle !== 'string' && subtitle, !hideCloseButton && !preventClose && (_jsx("span", { className: modalCloseButton, children: _jsx(IconButtonTertiary, { icon: "close", onTrigger: onToggle, size: "small", color: "subtle", label: "Close" }) }))] })), (body || tabs) && (_jsxs("div", { className: clsx(modalBody({ hasTabBar: !isEmpty(tabs) }), bodyClassName), children: [_jsx(React.Suspense, { children: body }), tabs && (_jsx("div", { className: modalTabbedContent, children: _jsx(TabBar, { sticky: true, tabs: tabs, gap: "none", styles: tabBarStyles }) }))] })), _jsx("footer", { className: modalFooter, children: footer })] }), _jsx(Backdrop, { elevation: 4, onTrigger: onToggle, visible: opened, canClose: !preventClose, preventEscKeyClose: preventEscKeyClose })] }));
});
