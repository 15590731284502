import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useUsingKeyboard } from '@accessible/using-keyboard';
import { TextLabel } from '../text/index.js';
import { Icon } from '../icon/index.js';
import { avatar, avatarImg } from './avatar.css.js';
const AvatarContents = ({ alt, fallbackText, imgSrc, size, }) => {
    // Convert alt string to initials
    const initials = fallbackText
        ?.match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    return (_jsxs(_Fragment, { children: [imgSrc && (_jsx("img", { src: imgSrc, className: avatarImg, alt: alt || fallbackText })), initials && !imgSrc && (_jsx(TextLabel, { size: size === 'xsmall' ? 'small' : 'medium', weight: "medium", children: size === 'small' || size === 'xsmall'
                    ? initials.charAt(0)
                    : initials })), !initials && !imgSrc && _jsx(Icon, { symbol: "user", size: size })] }));
};
const AvatarButton = forwardRef((props, ref) => {
    const { alt, className, fallbackText, imgSrc, onTrigger, size = 'medium', testId, label, ...otherProps } = props;
    const isUsingKeyboard = useUsingKeyboard(false);
    return (_jsx("button", { ...otherProps, className: clsx({
            'is-using-keyboard': isUsingKeyboard,
        }, [avatar({ size, hasTrigger: true }), className]), "data-testid": testId, onClick: (event) => onTrigger({
            type: 'click',
            event,
        }), onKeyDown: (event) => {
            if (event.key === 'Enter') {
                onTrigger({
                    type: 'key',
                    key: event.key,
                    event,
                });
            }
        }, "aria-label": label, ref: ref, type: "button", children: _jsx(AvatarContents, { alt: alt, fallbackText: fallbackText, imgSrc: imgSrc, size: size }) }));
});
const AvatarBase = forwardRef((props, ref) => {
    const { alt, className, fallbackText, imgSrc, size = 'medium', testId, ...otherProps } = props;
    return (_jsx("div", { className: clsx([avatar({ size }), className]), "data-testid": testId, ref: ref, ...otherProps, children: _jsx(AvatarContents, { alt: alt, fallbackText: fallbackText, imgSrc: imgSrc, size: size }) }));
});
const hasButtonProps = (props) => 'onTrigger' in props;
export const Avatar = React.forwardRef((props, ref) => {
    if (hasButtonProps(props)) {
        return (_jsx(AvatarButton, { ref: ref, ...props }));
    }
    return (_jsx(AvatarBase, { ref: ref, ...props }));
});
