import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useUsingKeyboard } from '@accessible/using-keyboard';
import clsx from 'clsx';
import { horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useClickAway } from '@knapsack/hooks';
import { IconButtonTertiary } from '../button/index.js';
import { Icon } from '../icon/index.js';
import { TextLabel } from '../text/index.js';
import { tab, tabInner, tabLabel, tabActionTrigger, } from './tab.css.js';
import { Badge } from '../badge/index.js';
import { Flex } from '../layout/flex/index.js';
/**
 * Switches between a span and a button for inline renaming
 */
const TabWrapper = ({ children, disabled = false, isHidden = false, isRenaming, onTrigger, selected = false, selectedStyle = 'underline', hasError = false, hasIcon = false, }) => {
    const isUsingKeyboard = useUsingKeyboard(false);
    if (isRenaming) {
        return (_jsx("span", { className: clsx({
                'is-using-keyboard': isUsingKeyboard,
            }, [tabInner({ selected, hasError, hasIcon, isRenaming })]), children: children }));
    }
    return (_jsx("button", { type: "button", className: clsx({
            'is-using-keyboard': isUsingKeyboard,
        }, [
            tabInner({
                selected,
                selectedStyle,
                hasIcon,
                isHidden,
                isRenaming: false,
            }),
        ]), tabIndex: 0, disabled: disabled, onClick: (event) => onTrigger({
            type: 'click',
            event,
        }), onKeyDown: (event) => {
            if (event.key !== 'Enter')
                return;
            onTrigger({
                type: 'key',
                key: event.key,
                event,
            });
        }, "data-testid": "tabNavTrigger", children: children }));
};
export const Tab = ({ badge, canEdit = false, canReorder = false, disabled = false, icon, id, isRenaming, isHidden, label, menuIcon, onMenuTrigger, onTabRename, onTrigger, selected = false, selectedStyle = 'underline', setIsRenaming, testId, }) => {
    const [showActionTriggerIcon, setShowActionTriggerIcon] = useState(false);
    const actionTriggerTooltip = (_jsxs(Flex, { direction: "column", align: "start", children: [canReorder && (_jsxs(TextLabel, { size: "small", weight: "bold", display: "block", children: ["Drag ", _jsx(TextLabel, { size: "small", children: "to move." })] })), canEdit && !!onMenuTrigger && (_jsxs(TextLabel, { size: "small", weight: "bold", children: ["Click ", _jsx(TextLabel, { size: "small", children: "to open menu." })] }))] }));
    const canDragAndDrop = canEdit && canReorder;
    const showActionTrigger = (canEdit && !!onMenuTrigger) || canDragAndDrop;
    const actionTriggerIcon = canDragAndDrop ? 'drag' : 'more';
    const restingIcon = menuIcon || actionTriggerIcon;
    const renameFieldRef = React.useRef(null);
    // Drag and Drop
    const { attributes: dragAttributes, listeners: dragListeners, setNodeRef, transform, transition, isDragging, } = useSortable({
        id,
        disabled: !canDragAndDrop,
        strategy: horizontalListSortingStrategy,
    });
    const dragAndDropStyle = {
        transform: CSS.Transform.toString({
            scaleX: 1,
            scaleY: 1,
            x: transform?.x ?? 0,
            y: transform?.y ?? 0,
        }),
        transition,
    };
    // Inline Renaming
    const [hasError, setHasError] = useState(false);
    function handleTabRenameSave(value) {
        setIsRenaming(false);
        setHasError(false);
        if (value === label)
            return;
        onTabRename({
            tabId: id,
            value,
        });
    }
    /**
     * @todo: Replace with something better.
     */
    function handleTabRenameTooShort() {
        // eslint-disable-next-line no-alert
        alert('Tab names cannot be less than two characters.');
    }
    useEffect(() => {
        if (!isRenaming)
            return;
        renameFieldRef.current.focus();
    }, [isRenaming]);
    useClickAway(renameFieldRef, () => {
        if (!canEdit && !isRenaming)
            return;
        const value = renameFieldRef.current.textContent;
        setHasError(value.length < 2);
        if (hasError) {
            return handleTabRenameTooShort();
        }
        handleTabRenameSave(value);
    });
    return (_jsxs("li", { id: id, className: tab({ isDragging }), ref: setNodeRef, style: dragAndDropStyle, "data-state": selected ? 'active' : 'inactive', "data-testid": testId, children: [showActionTrigger && (_jsx(IconButtonTertiary, { className: tabActionTrigger, color: "subtle", label: "Tab Action Trigger", tooltipContent: actionTriggerTooltip, onTrigger: (info) => onMenuTrigger({ info, tabId: id }), icon: showActionTriggerIcon ? actionTriggerIcon : restingIcon, size: "xsmall", testId: "tab-action-trigger", onMouseEnter: () => setShowActionTriggerIcon(true), onMouseLeave: () => setShowActionTriggerIcon(false), ...(canDragAndDrop ? dragListeners : {}), ...(canDragAndDrop ? dragAttributes : {}) })), _jsxs(TabWrapper, { isHidden: isHidden, isRenaming: isRenaming, selected: selected, onTrigger: onTrigger, disabled: disabled, hasError: hasError, hasIcon: !!icon, selectedStyle: selectedStyle, children: [icon && _jsx(Icon, { symbol: icon, size: "xsmall" }), canEdit && isRenaming ? (_jsx("span", { className: tabLabel({ selected, selectedStyle }), contentEditable: true, onKeyDown: (event) => {
                            // prevent new lines
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }, onKeyUp: (event) => {
                            const value = event.currentTarget.innerText;
                            setHasError(value.length < 2);
                            if (hasError && event.key === 'Enter') {
                                return handleTabRenameTooShort();
                            }
                            if (!hasError && event.key === 'Enter') {
                                handleTabRenameSave(value);
                            }
                            else if (event.key === 'Escape') {
                                setIsRenaming(false);
                            }
                        }, ref: renameFieldRef, role: "textbox", suppressContentEditableWarning: true, tabIndex: 0, children: label })) : (_jsx("span", { className: tabLabel({ selected, selectedStyle }), children: label })), badge && _jsx(Badge, { ...badge, size: "small" })] })] }));
};
