import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import clsx from 'clsx';
import { useState } from 'react';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { DndContext, PointerSensor, useDroppable, useSensor, } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { camelCase } from '@knapsack/utils';
import { useStateFromProp } from '@knapsack/hooks';
import { Tab } from './tab.js';
import { tabBarInner, tabBarStyles, tabBarList, } from './tab-bar.css.js';
import { SuspenseLoader } from '../../utils/suspense-loader.js';
import { Menu, MenuIcon, MenuItem, MenuItemNested, MenuText, } from '../menu/index.js';
import { layoutSpacing } from '../../css-utils.css.js';
import { tabTheme } from './tab.css.js';
import { assignThemeVars } from '../../utils/assign-theme-vars.js';
export const TabBar = ({ ariaLabel, afterTabs, beforeTabs, canEdit = false, gap = 'xsmall', menuItems, onTabChange, onTabMenuTrigger, onTabRename, onTabReorder, sticky = false, tabs, testId, theme, styles, selectedStyle, }) => {
    // useStateFromProp so that when tabs change, the active tab is updated
    const [activeId, setActiveId] = useStateFromProp(tabs?.find((t) => t.isInitiallyActive)?.id || tabs?.[0]?.id);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [menuTabId, setMenuTabId] = useState(null);
    const [isRenaming, setIsRenaming] = useState(false);
    // Drag and Drop
    const canReorder = canEdit && onTabReorder !== undefined;
    const { setNodeRef } = useDroppable({ id: 'tab-bar-droppable' });
    const sensor = useSensor(PointerSensor, {
        // delay the drag action by x ms and if the cursor is moved x px the delay is reset
        activationConstraint: {
            delay: 150,
            tolerance: 5,
        },
    });
    return (_jsxs(_Fragment, { children: [_jsx("nav", { className: clsx([
                    tabBarStyles({ sticky }),
                    layoutSpacing({ marginBottom: gap }),
                ]), "data-testid": testId, "aria-label": ariaLabel || 'Tab Bar', style: styles?.tabBar, children: _jsxs("div", { className: tabBarInner, children: [beforeTabs, _jsx(DndContext, { sensors: [sensor], onDragEnd: (e) => {
                                if (!canReorder)
                                    return;
                                const { active, over } = e;
                                const index = tabs.findIndex((item) => item.id === active.id);
                                const destination = tabs.findIndex((item) => item.id === over?.id);
                                if (index !== destination && destination !== -1) {
                                    onTabReorder({ index, destination });
                                }
                            }, modifiers: [restrictToHorizontalAxis], children: _jsx(SortableContext, { items: tabs, disabled: !canReorder, children: _jsx("ul", { ref: setNodeRef, className: tabBarList, style: assignThemeVars(tabTheme, theme), children: tabs.map((tab) => (_createElement(Tab, { ...tab, canEdit: canEdit, canReorder: canReorder, isRenaming: isRenaming && menuTabId === tab.id, key: tab.id, onMenuTrigger: ({ info }) => {
                                            setMenuAnchorEl(info.event.currentTarget);
                                            setMenuIsOpen(true);
                                            setMenuTabId(tab.id);
                                            onTabMenuTrigger?.({ info, tabId: tab.id });
                                        }, onTrigger: () => {
                                            setActiveId(tab.id);
                                            onTabChange?.(tab.id);
                                        }, onTabRename: ({ value }) => {
                                            onTabRename?.({ tabId: tab.id, value });
                                            setMenuAnchorEl(null);
                                        }, selected: activeId === tab.id, selectedStyle: selectedStyle, setIsRenaming: setIsRenaming, testId: tab.testId || `${testId}-tab` }))) }) }) }), afterTabs] }) }), _jsx("div", { id: `tabbed-content-${activeId}`, style: styles?.tabBarContent, children: _jsx(SuspenseLoader, { children: tabs?.find(({ id }) => activeId === id)?.content }) }), canEdit && (_jsxs(Menu, { anchorEl: menuAnchorEl, onClose: () => setMenuIsOpen(false), open: menuIsOpen, placement: "bottom-start", testId: "tabBarMenu", children: [!!onTabRename && (_jsxs(MenuItem, { onTrigger: () => {
                            setIsRenaming(true);
                            setMenuIsOpen(false);
                        }, testId: "tabBarMenuItem--rename", children: [_jsx(MenuIcon, { symbol: "edit" }), _jsx(MenuText, { children: "Rename" })] })), menuItems?.map((item) => {
                        if (item.children) {
                            return (_jsx(MenuItemNested, { disabled: item.disabled, label: _jsxs(_Fragment, { children: [_jsx(MenuIcon, { symbol: item.icon }), _jsx(MenuText, { children: item.label })] }), parentMenuOpen: menuIsOpen, testId: `tabBarMenuItem--${camelCase(item.label)}`, wrapLabel: false, children: item.children }, item.label));
                        }
                        return (_jsxs(MenuItem, { disabled: item.disabled, onTrigger: (info) => {
                                setMenuAnchorEl(null);
                                setMenuIsOpen(false);
                                item.onTrigger({ info, tabId: menuTabId });
                            }, testId: `tabBarMenuItem--${camelCase(item.label)}`, type: item.type || 'default', children: [item.icon && _jsx(MenuIcon, { symbol: item.icon }), _jsx(MenuText, { children: item.label })] }, item.label));
                    })] }))] }));
};
