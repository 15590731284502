import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { buttonPrimaryStyles } from './button-primary.css.js';
import { ButtonBase, IconButtonBase, } from '../button-base/index.js';
export const ButtonPrimary = forwardRef((butttonBaseProps, ref) => {
    return (_jsx(ButtonBase, { ref: ref, theme: buttonPrimaryStyles, ...butttonBaseProps }));
});
export const IconButtonPrimary = forwardRef((butttonBaseProps, ref) => {
    return (_jsx(IconButtonBase, { ref: ref, theme: buttonPrimaryStyles, ...butttonBaseProps }));
});
