import { useEffect } from 'react';
const handleFocusTrap = (event, firstElement, lastElement) => {
    if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
        }
        else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
        }
    }
};
export const useFocusTrap = ({ trapElement, active, }) => {
    useEffect(() => {
        if (trapElement === null)
            return;
        const focusableElements = trapElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        if (active) {
            firstElement?.focus();
            trapElement.addEventListener('keydown', (event) => handleFocusTrap(event, firstElement, lastElement));
        }
        return trapElement.removeEventListener('keydown', (event) => handleFocusTrap(event, firstElement, lastElement));
    }, [active, trapElement]);
};
