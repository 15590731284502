import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { Menu } from '../menu/index.js';
import { MenuItem } from './menu-item.js';
import { MenuText } from '../menu-text/menu-text.js';
import { menuItemNestedStyles } from './menu-item.css.js';
/**
 * Use as a drop-in replacement for `<MenuItem>` when you need to add cascading
 * menu elements as children to this component.
 */
export const MenuItemNested = React.forwardRef((props, ref) => {
    const { parentMenuOpen, label, children, wrapLabel = true, onClose, onTrigger, testIdOnSubmenu, nestedMenuHeader, nestedMenuFooter, nestedMenuSize = 'auto', hideCaret = false, ...extraMenuItemProps } = props;
    const containerRef = useRef(null);
    const menuContainerRef = useRef(null);
    const menuItemRef = useRef(null);
    const openTimeoutRef = useRef();
    const closeTimeoutRef = useRef();
    useImperativeHandle(ref, () => menuItemRef.current);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onTrigger?.({
                type: 'key',
                key: 'Enter',
                event: event,
            });
        }
        if (event.key === 'ArrowLeft') {
            setIsSubMenuOpen(false);
            menuItemRef.current?.focus();
        }
        if (event.key === 'ArrowRight') {
            setIsSubMenuOpen(true);
            menuContainerRef.current?.querySelector('li')?.focus();
        }
        if (event.key === 'Escape') {
            setIsSubMenuOpen(false);
        }
    };
    function handleMouseEnter() {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }
        if (openTimeoutRef.current) {
            clearTimeout(openTimeoutRef.current);
        }
        openTimeoutRef.current = setTimeout(() => {
            setIsSubMenuOpen(true);
        }, 200);
    }
    function handleMouseLeave() {
        if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
        }
        if (openTimeoutRef.current) {
            clearTimeout(openTimeoutRef.current);
        }
        closeTimeoutRef.current = setTimeout(() => {
            setIsSubMenuOpen(false);
        }, 350);
    }
    // cleanup and close any open timeouts
    useEffect(() => {
        return () => {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
            }
            if (openTimeoutRef.current) {
                clearTimeout(openTimeoutRef.current);
            }
        };
    }, []);
    const open = isSubMenuOpen && parentMenuOpen;
    return (_jsxs("div", { ref: containerRef, onKeyDown: handleKeyDown, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, tabIndex: props.disabled ? -1 : 0, role: "menu", children: [_jsx(MenuItem, { ...extraMenuItemProps, onTrigger: (info) => {
                    onTrigger?.(info);
                }, className: menuItemNestedStyles, selected: open || extraMenuItemProps.selected, ref: menuItemRef, iconRight: hideCaret
                    ? undefined
                    : {
                        symbol: 'caret-right',
                        size: 'xsmall',
                        color: 'subtle',
                    }, children: wrapLabel ? _jsx(MenuText, { children: label }) : label }), _jsx(Menu, { type: "submenu", anchorEl: menuItemRef.current, placement: "right-start", open: open, fallbackPlacements: ['left-start', 'bottom-end'], onClose: () => {
                    setIsSubMenuOpen(false);
                    onClose?.();
                }, size: nestedMenuSize, testId: testIdOnSubmenu, header: nestedMenuHeader, footer: nestedMenuFooter, children: _jsx("div", { ref: menuContainerRef, style: { pointerEvents: 'auto' }, children: children }) })] }));
});
