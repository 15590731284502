import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef } from 'react';
import { backdropStyles } from './backdrop.css.js';
export const Backdrop = forwardRef(({ visible = false, elevation = 1, onTrigger, canClose, preventEscKeyClose = false, }, ref) => {
    const onTriggerRef = useRef(onTrigger);
    onTriggerRef.current = onTrigger;
    useEffect(() => {
        if (visible && canClose) {
            document.body.style.overflow = 'hidden';
            if (!preventEscKeyClose) {
                const escFunction = (event) => {
                    if (event.key === 'Escape') {
                        onTriggerRef.current({
                            type: 'keyNative',
                            key: event.key,
                            event,
                        });
                    }
                };
                window.addEventListener('keydown', escFunction);
                return () => {
                    document.body.style.overflow = 'unset';
                    window.removeEventListener('keydown', escFunction);
                };
            }
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [visible, canClose, preventEscKeyClose]);
    return (_jsx("div", { ref: ref, className: backdropStyles({ visible, elevation, canClose }), onClick: (event) => {
            if (canClose) {
                onTrigger({
                    type: 'click',
                    event,
                });
            }
        }, role: "presentation" }));
});
