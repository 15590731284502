import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import clsx from 'clsx';
import { isEmpty, isPromise } from '@knapsack/utils';
import { menuItemStyles } from './menu-item.css.js';
import { Badge } from '../../../badge/index.js';
import { Icon } from '../../../icon/index.js';
import { Tooltip } from '../../../tooltip/index.js';
import { MenuText } from '../menu-text/menu-text.js';
import { MenuAvatar } from '../menu-avatar/menu-avatar.js';
import { TextBody } from '../../../text/index.js';
import { componentSpacing } from '../../../../css-utils.css.js';
export const MenuItem = React.forwardRef(({ badge, children, selected, highlighted, disabled: disabledProp, analyticsAndTestLabel, tooltip = '', onTrigger: onTriggerProp, type, tabIndex, testId, className, onClick, onKeyDown, iconLeft, iconRight, dataTarget, rounded = true, label, avatar, helperText, ...otherProps }, ref) => {
    const [loading, setLoading] = useState(false);
    const disabled = disabledProp || loading;
    const onTrigger = (...args) => {
        const result = onTriggerProp?.(...args);
        if (isPromise(result)) {
            setLoading(true);
            result.finally(() => {
                setLoading(false);
            });
        }
        return result;
    };
    return (_jsxs("li", { ...otherProps, role: "menuitem", ref: ref, "data-target": dataTarget, className: clsx([
            menuItemStyles({
                selected,
                // programmatically prevent disabled items from being highlighted
                highlighted: highlighted && !disabled ? highlighted : false,
                disabled,
                rounded,
                type,
            }),
            className,
        ]), tabIndex: tabIndex || (disabled ? -1 : 0), "aria-disabled": disabled, 
        // aria-checked={selected}
        onClick: (event) => {
            if (disabled)
                return;
            if (onClick) {
                onClick(event);
            }
            if (onTrigger) {
                onTrigger({
                    type: 'click',
                    event,
                });
            }
        }, onKeyDown: (event) => {
            if (disabled)
                return;
            if (onKeyDown) {
                onKeyDown(event);
            }
            if (onTrigger && event.key === 'Enter') {
                /** we should try to avoid preventDefault in most situations, however this
                 * one is necessary to auto-close menus via keyboard enter */
                event.preventDefault();
                onTrigger({
                    type: 'key',
                    key: event.key,
                    event,
                });
            }
        }, "data-testid": testId, "data-test-menu-item-label": analyticsAndTestLabel, "data-analytics-menu-item-label": analyticsAndTestLabel, children: [iconLeft && _jsx(Icon, { ...iconLeft }), avatar && _jsx(MenuAvatar, { ...avatar }), children, label && (_jsxs(MenuText, { color: type === 'action' || type === 'destructive'
                    ? 'inherit'
                    : 'default', children: [label, helperText && (_jsx(TextBody, { className: componentSpacing({ marginTop: 'xxsmall' }), color: "subtle", size: "xsmall", children: helperText }))] })), badge && !isEmpty(badge.label.toString()) && (_jsx(Badge, { size: "small", ...badge })), iconRight && !loading && _jsx(Icon, { ...iconRight }), loading && _jsx(Icon, { symbol: "loading", spinning: true }), tooltip && (_jsx(Tooltip, { tooltipContent: tooltip, placement: "right", autoWrap: true, children: _jsx(Icon, { symbol: "info-circle", size: "xsmall" }) }))] }));
});
